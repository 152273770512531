import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler
} from 'reactstrap'
import PropTypes from "prop-types"
import Link from './link'
import Navigation from 'reactstrap-json-nav'
import navigation from '../data/navigation'
import logo from '../assets/images/geek-station-logo-only.png'
import styled from 'styled-components'

let StyledNavbar = styled(props => <Navbar {...props} />)`
  position: sticky;
  top: 0;
  z-index: 10;
`

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <StyledNavbar color="light" light expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={logo} id="site-logo" alt="Logo" height="30px" />
          {props.siteTitle}
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation
            json={navigation}
            link={Link}
            activeclassname="active"
          />
        </Collapse>
      </Container>
    </StyledNavbar>
  );
}

Header.defaultProps = {
  siteTitle: PropTypes.string
}

export default Header;
