import React from 'react'
import { Container } from 'reactstrap'
import { HeaderItem } from './bannerHelper'
import { FaMapMarkerAlt } from 'react-icons/fa';

let Banner = ({
  title,
  phone,
  address
}) => {
  // const phone_url = 'tel:' + phone.replace(/ /g, '')
  return (
    <div className="bg-light">
      <Container>
        <div className="d-flex py-2 align-items-center">
          <div className="d-flex mr-auto">
            {/* <span className="d-none d-sm-inline font-weight-bold">
              {title}
            </span> */}
          </div>
          <div className="d-flex">
            <HeaderItem
              text={address}
              type="address"
              Icon={FaMapMarkerAlt}
              url="https://goo.gl/maps/sphG6f7wwdkCbFza8"
              klass="d-lg-flex d-none mr-3"
            />
            {/* 電話番号ができたら有効化 */}
            {/* <HeaderItem
              text={phone}
              type="phone"
              Icon={FaPhone}
              url={phone_url}
              klass="d-sm-flex d-none mr-3"
            /> */}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Banner
